<template>
  <Teleport to="body">
    <modal :show="trigger_modal" :class="props.source">
      <template #header>
        <div class="modal__header">
          <h4>{{ list.isSelectList ? 'Add this item to a list' : 'Add this item to a new list' }}  </h4>
          <div class="modal__close" @click.prevent="$emit('close')">
            <icon-close />
          </div>
        </div>
      </template>
      <template #body>
        <div class="modal__body">
          <product-list-item
            :product="props.product"
            :price="props.price"
            :quantity="list.product.quantity"
            :customer="props.customer"
            @updateQuantity="updateQuantity"
            :source="source"
          ></product-list-item>
        </div>
      </template>
      <template #footer>
        <div class="modal__footer">
          <template v-if="list.isSelectList">
            <div class="modal__footer__wishlist">
              <div class="modal__footer__wishlist__header">
                <p>Select List:</p>
                <p
                  @click="list.isSelectList = !list.isSelectList"
                  class="underline cursor-pointer"
                >
                  Create new list
                </p>
              </div>
              <div
                @click="list.selectList = !list.selectList"
                class="modal__footer__wishlist__select"
              >
                <div
                  :class="[
                    'modal__footer__wishlist__title',
                    {
                      'modal__footer__wishlist__title--active': list.selectList,
                    },
                  ]"
                >
                  <template v-if="!list.options.length">
                    <p class="light">No lists</p>
                  </template>
                  <template v-else>
                    <p class="light">
                      {{
                        list.selectedList ? list.selectedList.name : 'Select'
                      }}
                    </p>
                  </template>
                </div>
                <transition-expand>
                  <ul
                    v-if="list.selectList"
                    class="modal__footer__wishlist__options"
                  >
                    <li
                      @click="list.selectedList = option"
                      v-for="option in list.options"
                      :key="option.id"
                    >
                      <p v-if="option !== list.selectedList" class="light">
                        {{ option.name }}
                      </p>
                    </li>
                  </ul>
                </transition-expand>
              </div>
            </div>
            <div class="modal__footer__action__container">
              <button
                @click.prevent="handleAddToList"
                class="btn__base btn__base--dark"
                :disabled="
                  list.selectedList === null ||
                  list.validation.success ||
                  list.validation.error
                "
              >
                <template v-if="list.validation.success">
                  Added
                  <icon-check />
                </template>
                <template v-else> Add To List </template>
              </button>
            </div>
            <div
              v-if="list.validation.success || list.validation.error"
              class="modal__footer__validation"
            >
              <p
                v-if="list.validation.success && list.selectedList"
                class="modal__footer__validation--success"
              >
                {{ list.product.quantity }} x {{ title }} has been added to
                {{ list.selectedList.name }}. View the list
                <span @click="handleListDetailRedirect(list.selectedList.id)" class="underline"
                  >here</span
                >.
              </p>
              <p
                v-if="list.validation.error"
                class="modal__footer__validation--error"
              >
                There was an issue. Please try again soon.
              </p>
            </div>
          </template>
          <template v-else>
            <div class="modal__footer__wishlist">
              <div class="modal__footer__wishlist__header">
                <p>Specity a name for your new list (optional):</p>
                <p
                  @click="list.isSelectList = !list.isSelectList"
                  class="underline cursor-pointer"
                >
                  Add to an existing list?
                </p>
              </div>
              <div
                @click="list.selectList = !list.selectList"
                class="modal__footer__wishlist__new"
              >
                <input
                  v-model="list.newListName"
                  type="text"
                  placeholder="If blank, list will be named sequentially i.e. List #1, List #2"
                />
              </div>
            </div>
            <div class="modal__footer__action__container">
              <button
                @click.prevent="handleCreateNewList"
                class="btn__base btn__base--dark"
              >
                <template v-if="list.validation.success">
                  Added
                  <icon-check />
                </template>
                <template v-else> Create and add to list </template>
              </button>
            </div>
            <div
              v-if="list.validation.success || list.validation.error"
              class="modal__footer__validation"
            >
              <p
                v-if="list.validation.success"
                class="modal__footer__validation--success"
              >
                {{ list.product.quantity }} x {{ title }} has been added to
                {{ list.apiResponse.data.name }}. View the list
                <span @click="handleListDetailRedirect(list.apiResponse.data.id)" class="underline"
                  >here</span
                >.
              </p>
              <p
                v-if="list.validation.error"
                class="modal__footer__validation--error"
              >
                There was an issue. Please try again soon.
              </p>
            </div>
          </template>
        </div>
      </template>
    </modal>
  </Teleport>
</template>

<script setup>
import { reactive, onMounted, watch, onBeforeMount, onUpdated } from 'vue';
import { useCustomerStore } from '../../../stores/customer';
import axios from 'axios';
import Modal from '../Modal.vue';
import IconClose from '../icons/IconClose.vue';
import IconCheck from '../icons/IconCheck.vue';
import ProductListItem from '../ProductListItem.vue';
import TransitionExpand from '../transitions/TransitionExpand.vue';
import 'vue-select/dist/vue-select.css';
import { number } from 'yup';
import { windowRedirect } from '../../../utils/helpers';

const props = defineProps({
  trigger_modal: {
    type: Boolean,
    required: true,
  },
  product: {
    type: Object,
    required: true,
  },
  price: {
    type: Number,
    required: true,
  },
  customer: {
    type: Object,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
});

const { id, title, handle, media, variants } = props.product;

const useCustomer = useCustomerStore();

const {
  PS,
  localStorage: ls,
} = globalThis;

const emit = defineEmits(['close']);

const list = reactive({
  isSelectList: true,
  selectList: false,
  selectedList: null,
  customerToken: null,
  options: [],
  newListName: null,
  product: {
    quantity: 1,
  },
  validation: {
    success: false,
    error: false,
  },
  apiResponse: {},
});

watch(
  () => list.isSelectList,
  (first) => {
    if (first === true) {
      getListOptions();
    }
  }
);

const getListOptions = async () => {
  const config = {
    baseURL: '/a/ps',
    method: 'get',
    url: `${props.customer.id}/wishlist/options`,
    headers: {
      'Content-Type': 'application/json',
      'Custom-Token': ls.getItem('psAppToken'),
    },
  };
  try {
    const res = await axios(config);
    const options = await res.data;
    list.options = options.data;
    list.options.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
  } catch (err) {
    if (err.response.status === 400) {
      await useCustomer.psAppToken(props.customer.id);
    }
    throw err;
  }
};

const updateQuantity = (value) => {
  list.product.quantity = value;
};

const handleAddToList = async () => {
  const payload = {
    barcode: variants[0].barcode,
    handle: handle,
    image: media && media.length ? media[0].src : '',
    pid: id,
    quantity: list.product.quantity,
    sku: variants[0].sku,
    title: title,
    vid: variants[0].id,
  };

  const config = {
    baseURL: '/a/ps',
    method: 'post',
    url: `${props.customer.id}/wishlist/${list.selectedList.id}/add_item`,
    headers: {
      'Content-Type': 'application/json',
      'Custom-Token': ls.getItem('psAppToken'),
    },
    data: JSON.stringify(payload),
  };
  try {
    const res = await axios(config);
    list.apiResponse = res.data;
    list.validation.success = true;
    setTimeout(() => {
      list.validation.success = false;
    }, 10000);
  } catch (err) {
    if (err.response.status === 400) {
      await useCustomer.psAppToken(props.customer.id);
    } else {
      list.validation.error = true;
      setTimeout(() => {
        list.validation.error = false;
      }, 10000);
    }
    throw err;
  }
};

const handleCreateNewList = async () => {
  const payload = list.newListName
    ? {
        name: list.newListName,
        items: [
          {
            pid: id,
            vid: variants[0].id,
            title: title,
            image: media.length ? media[0].src : '',
            handle: handle,
            sku: variants[0].sku,
            barcode: variants[0].barcode,
            quantity: list.product.quantity,
          },
        ],
      }
    : {
        items: [
          {
            pid: id,
            vid: variants[0].id,
            title: title,
            image: media.length ? media[0].src : '',
            handle: handle,
            sku: variants[0].sku,
            barcode: variants[0].barcode,
            quantity: list.product.quantity,
          },
        ],
      };

  const config = {
    baseURL: '/a/ps',
    method: 'post',
    url: `${props.customer.id}/wishlist/create`,
    headers: {
      'Content-Type': 'application/json',
      'Custom-Token': ls.getItem('psAppToken'),
    },
    data: JSON.stringify(payload),
  };
  try {
    const res = await axios(config);
    list.apiResponse = res.data;
    list.validation.success = true;
    setTimeout(() => {
      list.validation.success = false;
    }, 5000);
  } catch (err) {
    if (err.response.status === 400) {
      await useCustomer.psAppToken(props.customer.id);
    } else {
      list.validation.error = true;
      setTimeout(() => {
        list.validation.error = false;
      }, 5000);
    }
    throw err;
  }
};

const handleListDetailRedirect = (listId) => {
  const url = new URL(window.origin + '/pages/order-lists');
  const params = {
    account_state: '/account/order/list/detail',
    customer_id: props.customer.id,
    list_id: listId,
  };
  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });
  windowRedirect(url);
};

onMounted(() => {
  getListOptions();
});
</script>
