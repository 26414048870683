<template>
  <form
    method="post"
    action="/cart/add"
    accept-charset="UTF-8"
    :class="[
      'product__form',
      { 'product__form--item': source === 'product__form--item' },
    ]"
    enctype="multipart/form-data"
  >
    <product-quantity
      :available="available"
      :quantity="product.quantity"
      :max_quantity="max_quantity"
      :disabled="disabled"
      @updateQuantity="updateQuantity"
    />
    <button
      @click.prevent="handleAddToCart"
      :disabled="!available || product.added || disabled"
      class="btn__base btn__base--dark"
    >
      <template v-if="source === 'product__form--item' && !grid.desktop">
        <template v-if="!product.added">
          <icon-cart />
        </template>
        <template v-else>
          <icon-check />
        </template>
      </template>
      <template v-else>
        <template v-if="!product.added">
          <p class="accent">Add to cart</p>
        </template>
        <template v-else>
          <p class="accent">Added</p>
          <icon-check />
        </template>
      </template>
    </button>
  </form>
</template>

<script setup>
import { reactive } from 'vue';
import { storeToRefs } from 'pinia';
import { useCartStore } from '../../stores/cart';
import { addItem } from '../../utils/cart';
import ProductQuantity from './ProductQuantity.vue';
import { useGrid } from 'vue-screen';
import IconCart from './icons/IconCart.vue';
import IconCheck from './icons/IconCheck.vue';

const useCart = useCartStore();
const { cart } = storeToRefs(useCart);

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
  variant_id: {
    type: Number,
    required: true,
  },
  max_quantity: {
    type: Number,
    required: true,
  },
  price: {
    type: Number,
    required: false,
  },
  variant_price: {
    type: Number,
    required: false,
  },
  available: {
    type: Boolean,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
});

const product = reactive({
  added: false,
  quantity: 1,
});

const updateQuantity = (value) => {
  product.quantity = value;
};

const grid = useGrid({
  mobile: '416px',
  tablet: '640px',
  'tablet-lg': '1024px',
  laptop: '1152px',
  desktop: '1460px',
  ultrawide: '2000px',
});

const handleAddToCart = async () => {
  try {
    await addItem(props.variant_id, 
    { 
      quantity: product.quantity
    });
    product.added = true;
    useCart.loadCartItems();
    setTimeout(() => {
      product.added = false;
    }, 1500);
  } catch (err) {
    throw err;
  }
};
</script>
