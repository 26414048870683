<template>
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0V3.75H12L8 0ZM7 3.75V0H1.5C0.65625 0 0 0.644531 0 1.40625V13.5938C0 14.3848 0.65625 15 1.5 15H10.5C11.3125 15 12 14.3848 12 13.5938V4.6875H8C7.4375 4.6875 7 4.27734 7 3.75ZM8.5 12.1875H3.5C3.21875 12.1875 3 11.9824 3 11.7188C3 11.4844 3.21875 11.25 3.5 11.25H8.5C8.75 11.25 9 11.4844 9 11.7188C9 11.9824 8.75 12.1875 8.5 12.1875ZM8.5 10.3125H3.5C3.21875 10.3125 3 10.1074 3 9.84375C3 9.60938 3.21875 9.375 3.5 9.375H8.5C8.75 9.375 9 9.60938 9 9.84375C9 10.1074 8.75 10.3125 8.5 10.3125ZM9 7.96875C9 8.23242 8.75 8.4375 8.5 8.4375H3.5C3.21875 8.4375 3 8.23242 3 7.96875C3 7.73437 3.21875 7.5 3.5 7.5H8.5C8.75 7.5 9 7.73437 9 7.96875Z"
      fill="#303030"
    />
  </svg>
</template>
