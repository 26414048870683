<template>
  <div v-show="data.mounted" class="product__list__item">
    <div class="product__list__item__title">
      <p class="accent">{{ title }}</p>
      <p class="accent">Price: {{ money(props.price) }}</p>
      <p class="light">SKU: {{ data.variants ? data.variants[0].sku : '' }}</p>
    </div>
    <div class="product__list__item__details">
      <product-quantity
        :available="available"
        :quantity="quantity"
        :max_quantity="data.variants ? data.variants[0].inventory_quantity : 0"
        source="list"
        @updateQuantity="updateQuantity"
      ></product-quantity>
      <div class="product__list__item__total">
        <p class="accent">Total: {{ money(props.price * quantity) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onBeforeMount, onMounted, computed } from 'vue';
import ProductQuantity from './ProductQuantity.vue';
import { money } from '../../utils/money';
import IconClose from './icons/IconClose.vue';

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  price: {
    type: Number,
    required: true,
  },
  quantity: {
    type: Number,
    required: true,
  },
  customer: {
    type: Object,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
});

const { title, available } = props.product;

// const price =
//   props.source === 'product-to-list' || props.source === 'more-from-brand' || props.source === 'recommended-api'
//     ? props.product.price
//     : props.product.price_min * 100;

const emit = defineEmits(['updateQuantity', 'discardProduct']);

const disabled = computed(() => {
  return !props.product.available;
});

const data = reactive({
  mounted: false,
  quantity: 1,
  min: '0',
  max: '15',
  variants: null,
});

const updateQuantity = (value) => {
  emit('updateQuantity', value);
};

const fetchVariants = async () => {
  try {
    const res = await fetch(`/products/${props.product.handle}?view=variants`);
    const variants = await res.json();
    data.variants = variants;
  } catch (err) {
    throw err;
  }
};

onBeforeMount(() => {
  fetchVariants();
});

onMounted(() => {
  data.mounted = true;
});
</script>
