<template>
  <div
    v-if="mounted"
    @mouseenter="productItemHover = true"
    @mouseleave="productItemHover = false"
    :class="[
      'product__item',
      `product__item--${props.source}`,
      { 'product__item--active': productItemHover },
    ]"
  >
    <a
      :href="`/products/${handle}`"
      class="product__item__link"
      :aria-label="`Product: ${title}`"
    >
      <div
        v-if="loggedIn && price"
        @click.prevent="triggerListModal = true"
        class="product__item__wishlist"
      >
        <IconWishlist />
        <p class="accent">Add to list</p>
      </div>
      <VImg
        class="product__item__image"
        :media="image"
        :fixed-size="imageSize"
        :hover-media="hoverImage"
        :hovering="productItemHover"
        :alt="title"
      />
    </a>
    <div class="product__item__details">
      <div class="product__item__title">
        <a :href="`/products/${handle}`" :aria-label="`Product: ${title}`">
          <p class="accent" v-text="title"></p>
        </a>
        <p class="small light">SKU: {{ sku }}</p>
      </div>
      <div v-if="loggedIn && price" class="product__item__price">
        <p class="accent" v-text="money(price)"></p>
        <p class="small light">(Plus GST)</p>
      </div>
    </div>
    <div v-if="loggedIn" class="product__item__quantity">
      <div class="product__item__quantity__atc">
        <product-form
          :variant_id="product.variants[0].id"
          :variant_price="variantPrice"
          :max_quantity="maxQuantity"
          :available="product.available"
          :customer="customer"
          :disabled="!price"
          :price="price"
          source="product__form--item"
        />
      </div>
      <template v-if="triggerListModal">
        <product-to-list-modal
          :product="product"
          :price="price"
          :customer="customer"
          :trigger_modal="triggerListModal"
          @close="triggerListModal = false"
          :source="props.source"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onBeforeMount, onMounted } from 'vue';
import axios from 'axios';
import { money } from '../../utils/money';
import VImg from './VImg.vue';
import ProductForm from './ProductForm.vue';
import ProductToListModal from './modals/ProductToListModal.vue';
import IconWishlist from './icons/IconWishlist.vue';
import TransitionExpand from './transitions/TransitionExpand.vue';
import { useGrid } from 'vue-screen';

const props = defineProps({
  product: {
    type: Object,
    required: true,
    default: () => {},
  },
  customer: {
    type: Object,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
});

const settings = PS.Settings;
const { handle, title, media = [] } = props.product;

const data = reactive({
  variants: null,
  metafields: null,
  imageFallback: null,
});

const price = computed(() => {
  return typeof props.product.variants[0].price === 'string'
    ? parseInt(props.product.variants[0].price.replace('.', ''))
    : props.product.variants[0].price;
});

const variantPrice = computed(() => {
  return typeof props.product.variants[0].price === 'string'
    ? parseInt(props.product.variants[0].price.replace('.', ''))
    : props.product.variants[0].price;
});

const mounted = ref(false);
const productItemHover = ref(false);
const triggerListModal = ref(false);
const imageSize = '800x';
const sku = props.product.variants[0].sku;

const grid = useGrid({
  mobile: '416px',
  tablet: '640px',
  'tablet-lg': '1024px',
  laptop: '1152px',
  desktop: '1460px',
  ultrawide: '2000px',
});

const loggedIn = computed(() => {
  return props.customer.logged_in && props.customer.company ? true : false;
});

const maxQuantity = computed(() => {
  return props.source === 'recommended-api' ||
    props.source === 'more-from-brand'
    ? data.variants[0].inventory_quantity
    : props.product.variants[0].inventory_quantity;
});

const image = computed(() => {
  if (
    props.source === 'recommended-api' ||
    props.source === 'more-from-brand'
  ) {
    return props.product.media && props.product.media[0]
      ? props.product.media[0]
      : {
          alt: null,
          height: 200,
          id: null,
          position: 1,
          src: `https:${settings.product_fallback_image}`,
          width: 200,
        };
  } else {
    return props.product.images_info && props.product.images_info[0]
      ? props.product.images_info[0]
      : {
          alt: null,
          height: 200,
          id: null,
          position: 1,
          src: `https:${settings.product_fallback_image}`,
          width: 200,
        };
  }
});

const hoverImage = computed(() => {
  if (
    props.source === 'recommended-api' ||
    props.source === 'more-from-brand'
  ) {
    return props.product.media && props.product.media.length > 1
      ? props.product.media[1]
      : {};
  } else {
    return props.product.images_info && props.product.images_info.length > 1
      ? props.product.images_info[1]
      : {};
  }
});

const fetchMetafields = async () => {
  const config = {
    method: 'get',
    url: `/products/${handle}?view=metafields`,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await axios(config);
  data.metafields = await res.data;
  mounted.value = true;
};

const fetchVariants = async () => {
  const config = {
    method: 'get',
    url: `/products/${handle}?view=variants`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const res = await axios(config);
  data.variants = await res.data;
};

onBeforeMount(async () => {
  if (
    (props.customer.logged_in && props.source === 'recommended-api') ||
    (props.customer.logged_in && props.source === 'more-from-brand')
  ) {
    await fetchVariants();
    await fetchMetafields();
  } else {
    mounted.value = true;
  }
});
</script>
